.markdown pre {
	position: relative;
}

iframe,
embed,
#iframely-content > *,
.embedly-card-hug {
	max-width: unset !important;
	width: 100% !important;
}

.iframely-embed {
	margin: 0 auto;
}

.fb_iframe_widget span {
	width: 100% !important;
}

twitterwidget {
	width: 100% !important;
}

.EmbeddedTweet {
	max-width: unset;
}
