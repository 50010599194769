.doc {
	width: 100%;
	height: 100vh;
	position: relative;
	padding: 57px 20px 100px 20px;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	z-index: 0;
}

.unfocused {
	border-radius: 1px;
}

.unfocused:hover {
	box-shadow: 0 0 0 3px #d2e3f7a1;
	border-radius: 3px;
}

.focused {
	box-shadow: 0 0 0 3px #d2e3f7;
	border-radius: 3px;
}

/* .doc.loading [data-slate-editor] > * > * > :not(span) {
	opacity: 0;
} */

.markdown h2 .anchor,
.markdown h3 .anchor,
.markdown h4 .anchor,
.markdown h5 .anchor,
.markdown h6 .anchor,
.markdown h2 .anchor:visited,
.markdown h3 .anchor:visited,
.markdown h4 .anchor:visited,
.markdown h5 .anchor:visited,
.markdown h6 .anchor:visited {
	opacity: 0;
	line-height: unset;
	color: rgba(0, 0, 0, 0.07);
	transition: 0.3s color;
}

.markdown h2 .anchor:hover,
.markdown h3 .anchor:hover,
.markdown h4 .anchor:hover,
.markdown h5 .anchor:hover,
.markdown h6 .anchor:hover {
	color: #0275f9;
}

.markdown h2:hover .anchor,
.markdown h3:hover .anchor,
.markdown h4:hover .anchor,
.markdown h5:hover .anchor,
.markdown h2:hover .anchor,
.markdown h6:hover .anchor {
	opacity: 1;
}

.doc.markdown .task-list-item input {
	margin: 0 0.5em 0.25em -1.3em;
}

.doc .loading {
	color: rgba(206, 217, 224, 0.2);
}

.doc.loading [data-slate-editor] > * > span,
.doc.loading [data-slate-editor] > * > div {
	border-color: rgba(206, 217, 224, 0.2) !important;
	border-radius: 2px;
	box-shadow: none !important;
	background: rgba(206, 217, 224, 0.2);
	background-clip: padding-box !important;
	cursor: default;
	color: transparent !important;
	animation: 1000ms linear infinite alternate skeleton-glow;
	pointer-events: none;
	user-select: none;
}

.doc.loading [data-slate-editor] > ul,
.doc.loading [data-slate-editor] > ol {
	list-style: none;
}

.doc.loading [data-slate-editor] > * {
	border: unset;
}

.doc.loading [data-slate-editor] > * > * :not(span) {
	opacity: 0;
}

/* TODO: Better way */
@media screen and (min-width: 768px) {
	.doc {
		padding-left: calc((100% - 728px) / 2);
		padding-right: calc((100% - 728px) / 2);
	}
}

.markdown hr {
	height: 1px;
	background-color: #eaecef;
	border: 0;
}

/* .link,
.hashtag,
.doctag,
.hashtag:visited,
.doctag:visited {
	color: #0275f9;
	text-decoration: none;
	white-space: nowrap;
}

.link,
.hashtag:hover,
.doctag:hover {
	text-decoration: underline;
}

.doctag:before,
.hashtag:before {
	font-family: 'Font Awesome 5 Free';
	display: inline-block;
	vertical-align: bottom;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

.doctag:before {
	content: '📝';
}

.hashtag:before {
	content: '🏷️';
} */

.doc.markdown span {
	white-space: normal;
}

.doc.markdown pre,
.doc .markdown code {
	background-color: rgba(0, 0, 0, 0.05);
	color: unset;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07);
}

.doc.markdown pre span {
	white-space: unset;
}

.markdown code {
	background-color: #f6f8fa;
	color: unset;
	box-shadow: 0 0 0 1px #eaecef;
	border-radius: 1px;
}

.doc.markdown table {
	width: 100%;
	display: table;
}

.markdown .InlineBlock code {
	padding: 2px 5px 0px 5px;
	margin: 0 5px;
	font-size: 100%;
	background-color: #f6f8fa;
	border-radius: 2px;
}

.link-popover {
	padding: 10px;
}

.bp3-icon svg {
	width: 18px;
	height: 18px;
}

.bp3-dark .doc {
	background-color: #30404d;
	color: #f5f8fa;
}

.bp3-dark .markdown h1,
.bp3-dark .markdown h2 {
	border-bottom: 1px solid #3b4f5c;
}

.bp3-dark .markdown blockquote {
	color: #b9c6d6;
	border-left: 0.25em solid #435562;
}

/* .bp3-dark .markdown pre,
.bp3-dark .markdown code {
	background-color: rgba(255, 255, 255, 0.05);
	color: unset;
	box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.07);
} */

.bp3-dark .markdown table tr {
	background-color: transparent;
}

.bp3-dark .markdown table th,
.bp3-dark .markdown table td {
	border: 1px solid #576672;
}

.bp3-dark ::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, 0.1);
}

.bp3-dark .unfocused:hover {
	box-shadow: 0 0 0 3px #6d7a8440;
}

.bp3-dark .focused {
	box-shadow: 0 0 0 3px #6d7a845c;
}

.bp3-dark .markdown h2 .anchor,
.bp3-dark .markdown h3 .anchor,
.bp3-dark .markdown h4 .anchor,
.bp3-dark .markdown h5 .anchor,
.bp3-dark .markdown h6 .anchor,
.bp3-dark .markdown h2 .anchor:visited,
.bp3-dark .markdown h3 .anchor:visited,
.bp3-dark .markdown h4 .anchor:visited,
.bp3-dark .markdown h5 .anchor:visited,
.bp3-dark .markdown h6 .anchor:visited {
	color: #576672;
}

.bp3-dark .markdown h2 .anchor:hover,
.bp3-dark .markdown h3 .anchor:hover,
.bp3-dark .markdown h4 .anchor:hover,
.bp3-dark .markdown h5 .anchor:hover,
.bp3-dark .markdown h6 .anchor:hover {
	color: #0366d6;
}
