.user {
	position: fixed;
	top: 16px;
	right: 20px;
	z-index: 1;
}

.user .profile,
.profile {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 50%;
}

.user .profile .picture,
.profile .picture {
	width: 32px;
	height: 32px;
	border-radius: 50%;
}

.user .profile .picture.logged-out {
	background-color: #f6f8fa;
}

.user .profile .google-profile-icon {
	position: absolute;
	width: 40px;
	height: 40px;
}

.logged-in {
	display: flex;
	padding: 10px;
}

.logged-in .picture {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.logged-in .info {
	padding-left: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.share {
	position: fixed;
	top: 12px;
	right: 80px;
	z-index: 1;
}

.share button {
	border-radius: 50%;
}

.share .bp3-icon svg {
	width: 18px;
	height: 18px;
}

.revisions {
	position: fixed;
	top: 12px;
	right: 125px;
	z-index: 1;
}

.revisions button {
	height: 40px;
	border-radius: ;
}

.bp3-menu-item-label .bp3-tag {
	padding: 0px 6px;
}

.bp3-icon svg.google-drive-icon {
	width: 22px;
	height: 22px;
	margin: -2px;
}

.bp3-toast .profile {
	width: 40px;
	height: 40px;
}
