.ReactBlock {
	width: 100%;
	height: 100%;
	/* box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1); */
	box-shadow: 0 0 0 1px #eaecef;
	border-radius: 2px;
	position: relative;
	margin-bottom: 10px;
}

.ReactBlock .output {
	max-height: 640px;
	overflow: auto;
	padding: 10px;
	/* overflow: scroll;
	padding: 10px 0px 0px 10px; */
	position: relative;
}

.ReactBlock .actions {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	font-size: 14px;
	padding: 8px;
	text-align: right;
	z-index: 1;
	opacity: 0;
	transition: 0.3s opacity;
}

.ReactBlock:hover .actions {
	opacity: 1;
}

.ReactBlock .actions svg {
	width: 16px;
	height: 16px;
}

.ReactBlock .bp3-icon svg {
	width: 1em;
	height: 1em;
}

.ReactBlock .output .actions {
	right: unset;
	top: 0;
	left: 0;
	justify-content: flex-start;
	text-align: left;
}

.ReactBlock .actions > * {
	margin-left: 10px;
}

.ReactBlock .output .actions > * {
	margin-left: 0;
	margin-right: 10px;
}

.ReactBlock table {
	width: auto;
}

.ReactBlock .output pre {
	font-size: 14px;
	font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
		monospace;
	background-color: transparent;
	box-shadow: none;
	margin: 0;
}

.ReactBlock .output table {
	overflow: visible;
	margin: 0;
}

.ReactBlock *::-webkit-input-placeholder {
	height: 1em;
}

.bp3-dark .ReactBlock {
	box-shadow: 0 0 0 1px #6d7a8440;
}

input[type='color'].bp3-button:empty {
	padding: 2px 4px !important;
}

.leaflet-container {
	width: 100%;
	height: 480px;
}

.markdown .leaflet-container a:hover {
	text-decoration: none;
}

.MonacoBlock {
}

.MonacoBlock .monaco-editor.focused,
.MonacoBlock .monaco-editor *.focused {
	box-shadow: none;
}

.MonacoBlock .monaco-editor .scroll-decoration {
	box-shadow: none;
}

.MonacoBlock .monaco-editor,
.MonacoBlock .monaco-editor-background,
.MonacoBlock .monaco-editor .inputarea.ime-input {
	background-color: transparent;
}

.MonacoBlock .monaco-editor,
.MonacoBlock .monaco-editor .inputarea.ime-input,
.MonacoBlock .monaco-editor .line-numbers {
	color: #bbbbbb;
}

.bp3-dark .MonacoBlock .monaco-editor,
.bp3-dark .MonacoBlock .monaco-editor .inputarea.ime-input,
.bp3-dark .MonacoBlock .monaco-editor .line-numbers {
	color: #4b5861;
}

.MonacoBlock .monaco-editor .current-line ~ .line-numbers {
	color: #888888;
}

.bp3-dark .MonacoBlock .monaco-editor .current-line ~ .line-numbers {
	color: #5c6f7b;
}

.MonacoBlock .monaco-editor .margin {
	background-color: transparent;
}

.MonacoBlock .monaco-padding {
	background-color: #f6f8fa;
	padding: 14px 0;
}

.bp3-dark .MonacoBlock .monaco-padding {
	background-color: #304554;
}

.MonacoBlock .decorationsOverviewRuler {
	display: none;
}

.MonacoBlock .monaco-editor .minimap-slider,
.MonacoBlock .monaco-editor .minimap-slider .minimap-slider-horizontal {
	background: rgba(0, 0, 0, 0.05);
}

.MonacoBlock .monaco-editor .minimap-slider:hover,
.MonacoBlock .monaco-editor .minimap-slider:hover .minimap-slider-horizontal {
	background: rgba(0, 0, 0, 0.1);
}

.MonacoBlock .monaco-editor .minimap-slider.active,
.MonacoBlock .monaco-editor .minimap-slider.active .minimap-slider-horizontal {
	background: rgba(0, 0, 0, 0.1);
}

.bp3-dark .MonacoBlock .monaco-editor .minimap-slider,
.bp3-dark
	.MonacoBlock
	.monaco-editor
	.minimap-slider
	.minimap-slider-horizontal {
	background: rgba(255, 255, 255, 0.05);
}

.bp3-dark .MonacoBlock .monaco-editor .minimap-slider:hover,
.bp3-dark
	.MonacoBlock
	.monaco-editor
	.minimap-slider:hover
	.minimap-slider-horizontal {
	background: rgba(255, 255, 255, 0.1);
}

.bp3-dark .MonacoBlock .monaco-editor .minimap-slider.active,
.bp3-dark
	.MonacoBlock
	.monaco-editor
	.minimap-slider.active
	.minimap-slider-horizontal {
	background: rgba(255, 255, 255, 0.1);
}

.MonacoBlock .line-numbers {
	user-select: none;
}
