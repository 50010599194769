.content.error {
	display: flex;
	justify-content: center;
	align-items: center;
}

.error .bp3-card {
	max-width: 640px;
	max-height: 640px;
	display: flex;
	flex-direction: column;
}

.error .icon {
	margin-right: 10px;
}

.error .bp3-card pre {
	overflow: scroll;
}

.error *:last-child {
	margin-bottom: 0;
}
