body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica,
		sans-serif;
	font-weight: 400;
	overflow: hidden;
}

* {
	outline: 0 !important;
}

.content {
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

section {
	margin-top: 0;
	margin-bottom: 10px;
}

li > section {
	margin-top: 16px;
}

a {
	color: #0275f9;
	text-decoration: none;
	cursor: pointer;
}

a:hover {
	text-decoration: underline;
}

a:visited {
	color: #004ea6;
}

span[role='img'] {
	margin-right: 5px;
}

button,
input {
	user-select: none;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

/* ::-webkit-scrollbar-thumb:hover {
  background: #0366d6; 
} */

.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.rainbow {
	background: linear-gradient(
		to right,
		red,
		orange,
		yellow,
		green,
		cyan,
		blue,
		violet
	);
}

.bp3-dark a,
.bp3-dark .markdown a {
	color: #0275f9;
}

.bp3-dark a:visited,
.bp3-dark .markdown a:visited {
	color: #51a2ff;
}
