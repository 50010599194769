.user {
	position: fixed;
	top: 16px;
	right: 20px;
	z-index: 1;
}

.user .profile,
.profile {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	cursor: pointer;
	border-radius: 50%;
}

.user .profile .picture,
.profile .picture {
	width: 32px;
	height: 32px;
	border-radius: 50%;
}

.user .profile .picture.logged-out {
	background-color: #f6f8fa;
}

.user .profile .google-profile-icon {
	position: absolute;
	width: 40px;
	height: 40px;
}

.logged-in {
	display: -webkit-flex;
	display: flex;
	padding: 10px;
}

.logged-in .picture {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.logged-in .info {
	padding-left: 10px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.share {
	position: fixed;
	top: 12px;
	right: 80px;
	z-index: 1;
}

.share button {
	border-radius: 50%;
}

.share .bp3-icon svg {
	width: 18px;
	height: 18px;
}

.revisions {
	position: fixed;
	top: 12px;
	right: 125px;
	z-index: 1;
}

.revisions button {
	height: 40px;
	border-radius: ;
}

.bp3-menu-item-label .bp3-tag {
	padding: 0px 6px;
}

.bp3-icon svg.google-drive-icon {
	width: 22px;
	height: 22px;
	margin: -2px;
}

.bp3-toast .profile {
	width: 40px;
	height: 40px;
}

.content.error {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.error .bp3-card {
	max-width: 640px;
	max-height: 640px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.error .icon {
	margin-right: 10px;
}

.error .bp3-card pre {
	overflow: scroll;
}

.error *:last-child {
	margin-bottom: 0;
}

.ReactBlock {
	width: 100%;
	height: 100%;
	/* box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1); */
	box-shadow: 0 0 0 1px #eaecef;
	border-radius: 2px;
	position: relative;
	margin-bottom: 10px;
}

.ReactBlock .output {
	max-height: 640px;
	overflow: auto;
	padding: 10px;
	/* overflow: scroll;
	padding: 10px 0px 0px 10px; */
	position: relative;
}

.ReactBlock .actions {
	position: absolute;
	top: 0;
	right: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	font-size: 14px;
	padding: 8px;
	text-align: right;
	z-index: 1;
	opacity: 0;
	-webkit-transition: 0.3s opacity;
	transition: 0.3s opacity;
}

.ReactBlock:hover .actions {
	opacity: 1;
}

.ReactBlock .actions svg {
	width: 16px;
	height: 16px;
}

.ReactBlock .bp3-icon svg {
	width: 1em;
	height: 1em;
}

.ReactBlock .output .actions {
	right: unset;
	top: 0;
	left: 0;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	text-align: left;
}

.ReactBlock .actions > * {
	margin-left: 10px;
}

.ReactBlock .output .actions > * {
	margin-left: 0;
	margin-right: 10px;
}

.ReactBlock table {
	width: auto;
}

.ReactBlock .output pre {
	font-size: 14px;
	font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
		monospace;
	background-color: transparent;
	box-shadow: none;
	margin: 0;
}

.ReactBlock .output table {
	overflow: visible;
	margin: 0;
}

.ReactBlock *::-webkit-input-placeholder {
	height: 1em;
}

.bp3-dark .ReactBlock {
	box-shadow: 0 0 0 1px #6d7a8440;
}

input[type='color'].bp3-button:empty {
	padding: 2px 4px !important;
}

.leaflet-container {
	width: 100%;
	height: 480px;
}

.markdown .leaflet-container a:hover {
	text-decoration: none;
}

.MonacoBlock {
}

.MonacoBlock .monaco-editor.focused,
.MonacoBlock .monaco-editor *.focused {
	box-shadow: none;
}

.MonacoBlock .monaco-editor .scroll-decoration {
	box-shadow: none;
}

.MonacoBlock .monaco-editor,
.MonacoBlock .monaco-editor-background,
.MonacoBlock .monaco-editor .inputarea.ime-input {
	background-color: transparent;
}

.MonacoBlock .monaco-editor,
.MonacoBlock .monaco-editor .inputarea.ime-input,
.MonacoBlock .monaco-editor .line-numbers {
	color: #bbbbbb;
}

.bp3-dark .MonacoBlock .monaco-editor,
.bp3-dark .MonacoBlock .monaco-editor .inputarea.ime-input,
.bp3-dark .MonacoBlock .monaco-editor .line-numbers {
	color: #4b5861;
}

.MonacoBlock .monaco-editor .current-line ~ .line-numbers {
	color: #888888;
}

.bp3-dark .MonacoBlock .monaco-editor .current-line ~ .line-numbers {
	color: #5c6f7b;
}

.MonacoBlock .monaco-editor .margin {
	background-color: transparent;
}

.MonacoBlock .monaco-padding {
	background-color: #f6f8fa;
	padding: 14px 0;
}

.bp3-dark .MonacoBlock .monaco-padding {
	background-color: #304554;
}

.MonacoBlock .decorationsOverviewRuler {
	display: none;
}

.MonacoBlock .monaco-editor .minimap-slider,
.MonacoBlock .monaco-editor .minimap-slider .minimap-slider-horizontal {
	background: rgba(0, 0, 0, 0.05);
}

.MonacoBlock .monaco-editor .minimap-slider:hover,
.MonacoBlock .monaco-editor .minimap-slider:hover .minimap-slider-horizontal {
	background: rgba(0, 0, 0, 0.1);
}

.MonacoBlock .monaco-editor .minimap-slider.active,
.MonacoBlock .monaco-editor .minimap-slider.active .minimap-slider-horizontal {
	background: rgba(0, 0, 0, 0.1);
}

.bp3-dark .MonacoBlock .monaco-editor .minimap-slider,
.bp3-dark
	.MonacoBlock
	.monaco-editor
	.minimap-slider
	.minimap-slider-horizontal {
	background: rgba(255, 255, 255, 0.05);
}

.bp3-dark .MonacoBlock .monaco-editor .minimap-slider:hover,
.bp3-dark
	.MonacoBlock
	.monaco-editor
	.minimap-slider:hover
	.minimap-slider-horizontal {
	background: rgba(255, 255, 255, 0.1);
}

.bp3-dark .MonacoBlock .monaco-editor .minimap-slider.active,
.bp3-dark
	.MonacoBlock
	.monaco-editor
	.minimap-slider.active
	.minimap-slider-horizontal {
	background: rgba(255, 255, 255, 0.1);
}

.MonacoBlock .line-numbers {
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.markdown pre {
	position: relative;
}

iframe,
embed,
#iframely-content > *,
.embedly-card-hug {
	max-width: unset !important;
	width: 100% !important;
}

.iframely-embed {
	margin: 0 auto;
}

.fb_iframe_widget span {
	width: 100% !important;
}

twitterwidget {
	width: 100% !important;
}

.EmbeddedTweet {
	max-width: unset;
}

.doc {
	width: 100%;
	height: 100vh;
	position: relative;
	padding: 57px 20px 100px 20px;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	z-index: 0;
}

.unfocused {
	border-radius: 1px;
}

.unfocused:hover {
	box-shadow: 0 0 0 3px #d2e3f7a1;
	border-radius: 3px;
}

.focused {
	box-shadow: 0 0 0 3px #d2e3f7;
	border-radius: 3px;
}

/* .doc.loading [data-slate-editor] > * > * > :not(span) {
	opacity: 0;
} */

.markdown h2 .anchor,
.markdown h3 .anchor,
.markdown h4 .anchor,
.markdown h5 .anchor,
.markdown h6 .anchor,
.markdown h2 .anchor:visited,
.markdown h3 .anchor:visited,
.markdown h4 .anchor:visited,
.markdown h5 .anchor:visited,
.markdown h6 .anchor:visited {
	opacity: 0;
	line-height: unset;
	color: rgba(0, 0, 0, 0.07);
	-webkit-transition: 0.3s color;
	transition: 0.3s color;
}

.markdown h2 .anchor:hover,
.markdown h3 .anchor:hover,
.markdown h4 .anchor:hover,
.markdown h5 .anchor:hover,
.markdown h6 .anchor:hover {
	color: #0275f9;
}

.markdown h2:hover .anchor,
.markdown h3:hover .anchor,
.markdown h4:hover .anchor,
.markdown h5:hover .anchor,
.markdown h2:hover .anchor,
.markdown h6:hover .anchor {
	opacity: 1;
}

.doc.markdown .task-list-item input {
	margin: 0 0.5em 0.25em -1.3em;
}

.doc .loading {
	color: rgba(206, 217, 224, 0.2);
}

.doc.loading [data-slate-editor] > * > span,
.doc.loading [data-slate-editor] > * > div {
	border-color: rgba(206, 217, 224, 0.2) !important;
	border-radius: 2px;
	box-shadow: none !important;
	background: rgba(206, 217, 224, 0.2);
	background-clip: padding-box !important;
	cursor: default;
	color: transparent !important;
	-webkit-animation: 1000ms linear infinite alternate skeleton-glow;
	        animation: 1000ms linear infinite alternate skeleton-glow;
	pointer-events: none;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.doc.loading [data-slate-editor] > ul,
.doc.loading [data-slate-editor] > ol {
	list-style: none;
}

.doc.loading [data-slate-editor] > * {
	border: unset;
}

.doc.loading [data-slate-editor] > * > * :not(span) {
	opacity: 0;
}

/* TODO: Better way */
@media screen and (min-width: 768px) {
	.doc {
		padding-left: calc((100% - 728px) / 2);
		padding-right: calc((100% - 728px) / 2);
	}
}

.markdown hr {
	height: 1px;
	background-color: #eaecef;
	border: 0;
}

/* .link,
.hashtag,
.doctag,
.hashtag:visited,
.doctag:visited {
	color: #0275f9;
	text-decoration: none;
	white-space: nowrap;
}

.link,
.hashtag:hover,
.doctag:hover {
	text-decoration: underline;
}

.doctag:before,
.hashtag:before {
	font-family: 'Font Awesome 5 Free';
	display: inline-block;
	vertical-align: bottom;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

.doctag:before {
	content: '📝';
}

.hashtag:before {
	content: '🏷️';
} */

.doc.markdown span {
	white-space: normal;
}

.doc.markdown pre,
.doc .markdown code {
	background-color: rgba(0, 0, 0, 0.05);
	color: unset;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07);
}

.doc.markdown pre span {
	white-space: unset;
}

.markdown code {
	background-color: #f6f8fa;
	color: unset;
	box-shadow: 0 0 0 1px #eaecef;
	border-radius: 1px;
}

.doc.markdown table {
	width: 100%;
	display: table;
}

.markdown .InlineBlock code {
	padding: 2px 5px 0px 5px;
	margin: 0 5px;
	font-size: 100%;
	background-color: #f6f8fa;
	border-radius: 2px;
}

.link-popover {
	padding: 10px;
}

.bp3-icon svg {
	width: 18px;
	height: 18px;
}

.bp3-dark .doc {
	background-color: #30404d;
	color: #f5f8fa;
}

.bp3-dark .markdown h1,
.bp3-dark .markdown h2 {
	border-bottom: 1px solid #3b4f5c;
}

.bp3-dark .markdown blockquote {
	color: #b9c6d6;
	border-left: 0.25em solid #435562;
}

/* .bp3-dark .markdown pre,
.bp3-dark .markdown code {
	background-color: rgba(255, 255, 255, 0.05);
	color: unset;
	box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.07);
} */

.bp3-dark .markdown table tr {
	background-color: transparent;
}

.bp3-dark .markdown table th,
.bp3-dark .markdown table td {
	border: 1px solid #576672;
}

.bp3-dark ::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, 0.1);
}

.bp3-dark .unfocused:hover {
	box-shadow: 0 0 0 3px #6d7a8440;
}

.bp3-dark .focused {
	box-shadow: 0 0 0 3px #6d7a845c;
}

.bp3-dark .markdown h2 .anchor,
.bp3-dark .markdown h3 .anchor,
.bp3-dark .markdown h4 .anchor,
.bp3-dark .markdown h5 .anchor,
.bp3-dark .markdown h6 .anchor,
.bp3-dark .markdown h2 .anchor:visited,
.bp3-dark .markdown h3 .anchor:visited,
.bp3-dark .markdown h4 .anchor:visited,
.bp3-dark .markdown h5 .anchor:visited,
.bp3-dark .markdown h6 .anchor:visited {
	color: #576672;
}

.bp3-dark .markdown h2 .anchor:hover,
.bp3-dark .markdown h3 .anchor:hover,
.bp3-dark .markdown h4 .anchor:hover,
.bp3-dark .markdown h5 .anchor:hover,
.bp3-dark .markdown h6 .anchor:hover {
	color: #0366d6;
}

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica,
		sans-serif;
	font-weight: 400;
	overflow: hidden;
}

* {
	outline: 0 !important;
}

.content {
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
}

section {
	margin-top: 0;
	margin-bottom: 10px;
}

li > section {
	margin-top: 16px;
}

a {
	color: #0275f9;
	text-decoration: none;
	cursor: pointer;
}

a:hover {
	text-decoration: underline;
}

a:visited {
	color: #004ea6;
}

span[role='img'] {
	margin-right: 5px;
}

button,
input {
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

/* ::-webkit-scrollbar-thumb:hover {
  background: #0366d6; 
} */

.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.rainbow {
	background: -webkit-linear-gradient(
		left,
		red,
		orange,
		yellow,
		green,
		cyan,
		blue,
		violet
	);
	background: linear-gradient(
		to right,
		red,
		orange,
		yellow,
		green,
		cyan,
		blue,
		violet
	);
}

.bp3-dark a,
.bp3-dark .markdown a {
	color: #0275f9;
}

.bp3-dark a:visited,
.bp3-dark .markdown a:visited {
	color: #51a2ff;
}

